import React, {memo} from "react";
import {FOOTER_STYLES} from "./FooterStyles";

const currentDate = new Date();

export const LeftFooter = memo(({color, style}) => {

	return (
		<div className={"LeftFooter"} style={{...style, ...FOOTER_STYLES[color]}}>
			<div className="work-inquiry">
				<div>
					<div>Work inquiry:</div>
					<a href={"mailto:hello@et-alier.com"}>hello@et-alier.com</a>
				</div>
			</div>
			<div className="all-rights-reserved">
				<div>
					<div>
						All rights reserved
					</div>
					<div>
						&copy; Et-alier {currentDate.getFullYear()}
					</div>
				</div>
			</div>
		</div>
	);
});