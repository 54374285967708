import styles from "../../styles/variables.scss";


export const FOOTER_STYLES = {
	"BLACK": {
		color: styles.right_text_color,
		background: styles.right_background_color,
		borderColor: styles.right_text_color
	},
	"WHITE": {
		color: styles.left_text_color,
		background: styles.left_background_color,
		borderColor: styles.left_text_color
	}
}