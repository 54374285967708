import React from "react";

const SUB_MAP = {
	"education": "EDUCATION",
	"teaching_experience": "TEACHING EXPERIENCE",
	"work_experience": "WORK EXPERIENCE",
	"awards": "AWARDS",
	"selected_group_exhibitions": "SELECTED GROUP EXHIBITIONS",
	"books": "ISSUE",
	"websites": "WEB"
};

export const SUB_LIST = [
	"education",
	"teaching_experience",
	"work_experience",
	"awards",
	"selected_group_exhibitions"
];

export const SUB_FEATURED_LIST = [
	"books",
	"websites"
];

export const AboutCard = ({ data, person, sub }) => {
	if (data == null || data[person] == null || data[person][sub] == null || data[person][sub].length < 1) {
		return null;
	}

	return (
		<div className={sub}>
			<h3>{SUB_MAP[sub]}</h3>
			{data[person][sub].map(s => {
				return (
						<div className={"item"} key={s.id}>{s.text}</div>
				)
			})}
		</div>
	)
};

// person needs to be hajnalka or sarolta
export const aboutCardMap = (sub, person, aboutData) => {
	return (
		<AboutCard key={person + "-" +sub} data={aboutData} person={person} sub={sub} />
	);
}