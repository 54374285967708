
export const rightSideUp = () => {
	const rightElements = document.getElementsByClassName("right");
	if (rightElements && rightElements[0]) {
		rightElements[0].scrollTop = 0;
	}
};

export const leftSideUp = () => {
	const leftElements = document.getElementsByClassName("left");
	if (leftElements && leftElements[0]) {
		leftElements[0].scrollTop = 0;
	}
};

export const rightScrollHandle = () => {
	const rightElement = document.getElementsByClassName("right")?.[0];
	const alier = document.getElementById("alier");
	if (!rightElement || !alier) {
		console.error("Right element or alier element not found!");
		return;
	}
	if (rightElement.scrollTop > 25) {
		alier.style.height = "40px";
		alier.style.width = "112.5px";
		alier.parentElement.style.top = "31px";
		alier.style.marginRight = "-6px";
	} else {
		alier.style.height = "150px";
		alier.style.width = "500px";
		alier.parentElement.style.top = "100px";
		if (alier.style.marginRight) {
			alier.style.marginRight = "-37px";
		}
	}
};

export const leftScrollHandle = () => {
	const leftElement = document.getElementsByClassName("left")?.[0];
	const et = document.getElementById("et");
	if (!leftElement || !et) {
		console.error("Left element or et element not found!");
		return;
	}
	if (leftElement.scrollTop > 25) {
		et.style.height = "40px";
		et.style.width = "100px";
		et.parentElement.style.top = "29px";
		et.parentElement.style.marginLeft = "-5px";
		et.style.marginRight = "-10px";
		et.style.marginLeft = "-5px";
	} else {
		et.style.height = "150px";
		et.style.width = "360px";
		et.parentElement.style.top = "100px";
		et.style.marginRight = "-100px";
		et.style.marginLeft = "-30px";
	}
};
