import React from "react";
import "./PhoneModal.scss";
import {Link} from "react-router-dom";

export const PhoneModal = ({
    close,
    side
}) => {
  return (
      <div className={"PhoneModal " + side}>
          <div className={"box"} />
          <div className={"menu"}>
              <Link to={"/"} onClick={close} rel={"canonical"}>
                  <h1>Inside the box</h1>
              </Link>
              <Link to={"/otb"} onClick={close}>
                  <h1>Outside the box</h1>
              </Link>
              <Link to={"/about"} onClick={close}>
                  <h1>About</h1>
              </Link>
              <Link to={"/contact"} onClick={close}>
                  <h1>Mailbox</h1>
              </Link>
          </div>
          <div className={"x"} onClick={close}>
            ✖
          </div>
      </div>
  );
};