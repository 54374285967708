import React from "react";
import {
	Routes,
	BrowserRouter,
	Route
} from "react-router-dom";
import axios from "axios";
import {MainPage} from "./pages/MainPage/MainPage";
import {QueryClient, QueryClientProvider} from "react-query";
import {BASE_URL} from "./utils/config";
import {ProjectPage} from "./pages/ProjectPage/ProjectPage";
import {FourOFourPage} from "./pages/404/404page";

const queryClient = new QueryClient();

axios.defaults.baseURL = BASE_URL;

export const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Routes>
					<Route path="/project/itb/:id" element={<ProjectPage />} />
					<Route path="/project/otb/:id" element={<ProjectPage />} />
					<Route
						path={"/"}
						element={<MainPage />}
					/>
					<Route
						path={"/contact"}
						element={<MainPage />}
					/>
					<Route
						path={"/about"}
						element={<MainPage />}
					/>
					<Route
						path={"/otb"}
						element={<MainPage />}
					/>
					<Route
						path={"/contact/about"}
						element={<MainPage />}
					/>
					<Route
						path={"/contact/otb"}
						element={<MainPage />}
					/>

					<Route
						path={"*"}
						element={<FourOFourPage />}
					/>
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
};
