import React, {useState} from "react";
import {MainPageHeaderLeft} from "../MainPage/MainPageComponents/MainPageHeader/MainPageHeaderLeft";
import {MainPageHeaderRight} from "../MainPage/MainPageComponents/MainPageHeader/MainPageHeaderRight";
import "./404page.scss";
import cube from "../../media/levitation_box-01.svg";
import {MainPageMobileHeader} from "../MainPage/MainPageComponents/MainPageMobileHeader/MainPageMobileHeader";
import {PhoneModal} from "../../components/PhoneModal/PhoneModal";

export const FourOFourPage = () => {

	const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

	return (
		<div className={"FourOFourPage"}>
			<MainPageMobileHeader side={"left"} menuClick={() => setIsPhoneModalOpen(true)}/>
			<div className={"left"}>
				<MainPageHeaderLeft noFill/>
				<div className={"content-wrapper"}>
					<div className={"content"}>
						<h1>Oops!</h1>
						<h2>Page Not Found</h2>
						<h3>Ah, the dreaded 404.</h3>
						<h3>Let's get you back on track</h3>
						<h3 className={"arrow"}><a href={"/"} rel={"canonical"}>←</a></h3>
					</div>
				</div>
			</div>
			<div className={"right"}>
				<MainPageHeaderRight noFill/>
				<div className={"content-wrapper"}>
					<div className={"content"}>
						<img className={"floating"} src={cube} alt={"levitating-box"} width={"100%"} height={"100%"} />
					</div>
				</div>
			</div>
			{isPhoneModalOpen && (
				<PhoneModal
					side={"left"}
					close={() => setIsPhoneModalOpen(false)}
				/>
			)}
		</div>
	);
};