import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {EMPTY_CIRCLE, FULL_CIRCLE, getAboutLink, getOtbLink} from "../../../../utils/utils";
import {RightLogo} from "../MainPageLogo/RightLogo";
import "./MainPageHeader.scss";
import { rightScrollHandle, rightSideUp} from "./MainPageHeaderUtils";

export const MainPageHeaderRight = ({ noFill }) => {

	const location = useLocation().pathname;
	const right = location.includes("/about");

	useEffect(() => {
		const rightElement = document.getElementsByClassName("right")[0];
		rightElement.addEventListener("scroll", rightScrollHandle);

		return () => {
			rightElement.removeEventListener("scroll", rightScrollHandle);
		};
	}, []);

	useEffect(() => {
		rightScrollHandle();
	}, [location]);

	const leftCircle = () => {
		if (noFill) {
			return EMPTY_CIRCLE;
		}
		return !right ? FULL_CIRCLE : EMPTY_CIRCLE;
	};

	const rightCircle = () => {
		if (noFill) {
			return EMPTY_CIRCLE;
		}
		return right ? FULL_CIRCLE : EMPTY_CIRCLE;
	};

	return (
		<div className={"MainPageHeader"}>
			<div className={"header-right"}>
				<div className={"start"}>
					<Link to={getOtbLink()} onClick={rightSideUp} >
						<h3>
							Outside the box
						</h3>
						<div className={"circle"}>
							{leftCircle()}
						</div>
					</Link>
				</div>
				<div className={"mid"}>
					<Link to={getAboutLink()} onClick={rightSideUp} >
						<h3>
							About
						</h3>
						<div className={"circle"}>
							{rightCircle()}
						</div>
					</Link>
				</div>
				<div className={"end"}>
					<RightLogo right={right} />
				</div>
			</div>
		</div>
	);
};