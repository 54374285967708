import React from "react";
import "./ContactUs.scss";
import { ContactUsSocials } from "../../components/ContactUsSocials/ContactUsSocials";

export const ContactUs = () => {
  return (
    <div className={"ContactUs"}>
      <div className={"details"} id={"details"}>
        <span>Et-alier Budapest, Hungary</span>
        <div className={"work-inquiry-desktop"}>
          <h1>Work inquiry ➔
            <a href={"mailto:hello@et-alier.com"}>&nbsp;hello@et-alier.com</a>
          </h1>
        </div>
        <div className={"work-inquiry-mobile"}>
          <h1>Work inquiry: </h1>
          <h1>
            <a href={"mailto:hello@et-alier.com"}>&nbsp;hello@et-alier.com</a>
          </h1>
        </div>

      </div>
      <div className={"people-wrapper"} id={"people-wrapper"}>
        <div>
          <div><b>Sarolta Ágnes Erdélyi</b></div>
          <div>Creative Director</div>
          <a href={"mailto:sarolta@et-alier.com"} className={"email"}>sarolta@et-alier.com</a>
        </div>
        <div className={"hajnalka"}>
          <div><b>Hajnalka Illés</b></div>
          <div>Design Director</div>
          <a href={"mailto:hajnalka@et-alier.com"} className={"email"}>hajnalka@et-alier.com</a>
        </div>
      </div>
      <ContactUsSocials />
      <div className={"special-thanks"} id={"special-thanks"}>
        <h6>Coding: Tibor Szénási</h6>
        <h6>Copy & English translation: Melinda Mónika Erdélyi</h6>
        <h6>Portrait photos: Krisztina Szalay</h6>
        <h6>Special thanks to: Alexander Csomor</h6>

      </div>
    </div>
  );
};