import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  EMPTY_CIRCLE,
  FULL_CIRCLE,
  getAboutLink,
  getContactUsLink,
  getItbLink,
  getOtbLink
} from "../../../../utils/utils";

import etBlack from "../../../../media/et-underline-gro-black.svg";
import etWhite from "../../../../media/et-underline-antik-white.svg";
import alierBlack from "../../../../media/alier-gro-black.svg";
import alierWhite from "../../../../media/alier-antik-white.svg";

import "./ProjectPageHeader.scss";

const Left = ({leftIcon, iconClicked, location}) => {
  return (
    <div className={"header-left"}>
      <div className={"start"}>
        <img className="et" src={leftIcon} alt={""} width={"75px"} height={"40px"} onClick={iconClicked} />
      </div>
      <div className={"mid"}>
        <Link to={getContactUsLink()}>
          <h3>
            Mailbox
          </h3>
          <div className={"circle"}>
            {EMPTY_CIRCLE}
          </div>
        </Link>
      </div>

      <div className={"end"}>
        <Link to={getItbLink()}>
          <h3>
            Inside the box
          </h3>
          <div className={"circle"}>
            {location.includes("/itb") ? FULL_CIRCLE : EMPTY_CIRCLE}
          </div>
        </Link>
      </div>
    </div>
  );
};

const Right = ({rightIcon, iconClicked, location}) => {
  return (
    <div className={"header-right"}>
      <div className={"start"}>
        <Link to={getOtbLink()}>
          <h3>
            Outside the box
          </h3>
          <div className={"circle"}>
            {location.includes("/otb") ? FULL_CIRCLE : EMPTY_CIRCLE}
          </div>
        </Link>
      </div>
      <div className={"mid"}>
        <Link to={getAboutLink()}>
          <h3>
            About
          </h3>
          <div className={"circle"}>
            {EMPTY_CIRCLE}
          </div>
        </Link>
      </div>
      <div className={"end"}>
        <img className="alier" src={rightIcon} alt={""} width={"109px"} height={"40px"} onClick={iconClicked} />
      </div>
    </div>
  );
};

export const ProjectPageHeader = ({
  side
}) => {
  const navigate = useNavigate();

  const iconClicked = () => {
    navigate("/");
  }

  const location = useLocation().pathname;
  let leftIcon = etBlack;
  let rightIcon = alierBlack;

  if (location.includes("otb")) {
    leftIcon = etWhite;
    rightIcon = alierWhite;
  }

  return (
    <div className={"ProjectPageHeader"}>
      {side === "left" ? (
        <Left iconClicked={iconClicked} leftIcon={leftIcon} location={location}/>
      ) : (
        <Right iconClicked={iconClicked} rightIcon={rightIcon} location={location}/>
      )}
    </div>
  );
};