import React from "react";
import "./FilterBlock.scss";

export const FilterBlock = ({
  filters
}) => {
  return (
    <div className="FilterBlock" id={"filter-block"}>
      {filters?.map(filter => {
        return (
          <span key={filter.code}>
            {"#" + filter.code + " "}
          </span>
        );
      })}
    </div>
  );
}
