import React from "react";
import Lottie from "react-lottie-player";
import alierAnimation from "../../../../media/alier.json";
import reverseAlierAnimation from "../../../../media/alier-reverse.json";
import alierLogo from "../../../../media/alier-gro-white.svg";
import {isSafari} from "../../../../utils/utils";

export const RightLogo = ({right}) => {

	if (isSafari()) {
		if (right) {
			return (
				<div className={"alier"}>
					<img id={"alier"} src={alierLogo} height={"150px"} width={"500px"} alt={""}/>
				</div>
			)
		} else {
			return (
				<div className="alier">
					<Lottie
						id={"alier"}
						play
						loop={false}
						animationData={alierAnimation}
						style={{ height: '150px', width: '500px' }}
					/>
				</div>
			);
		}
	}

	return (
		<div className="alier">
			<Lottie
				id={"alier"}
				play
				loop={false}
				animationData={right ? reverseAlierAnimation : alierAnimation}
				style={{ height: '150px', width: '500px' }}
			/>
		</div>
	);
};