import React, {useState} from "react";
import whiteArrowUp from "../../../media/white-arrow-up.svg";
import whiteArrowDown from "../../../media/white-arrow-down.svg";
import "../AboutUs.scss";
import ReactMarkdown from "react-markdown";
import {aboutCardMap, SUB_FEATURED_LIST, SUB_LIST} from "../../../components/AboutCard/AboutCard";

const People = ({ aboutData }) => {
	return (
		<div className={"people"}>
			<img src={"/hajni.webp"} alt={"hajni"} className="image" width={3} height={2}/>
			<img src={"/saci.webp"} alt={"saci"} className="image" width={3} height={2}/>

			<div className="name">
				<b>Hajnalka Illés</b>
			</div>
			<div className="name">
				<b>Sarolta Ágnes Erdélyi</b>
			</div>

			<div className="about-me">
				{aboutData?.hajnalka?.introduction}
			</div>
			<div className="about-me">
				{aboutData?.sarolta?.introduction}
			</div>
		</div>
	);
};

const Arrows = ({ detailsOpen, setDetailsOpen }) => {
	return (
		<div className={"arrow"} onClick={() => setDetailsOpen(!detailsOpen)}>
			<img
				src={detailsOpen ? whiteArrowUp: whiteArrowDown}
				alt="white-arrow-up"
				width="50px"
				height="50px"
			/>
		</div>
	);
};

const Misc = ({ aboutData }) => {
	return (
		<div className={"details"}>
			<div className={"hajnalka"}>
				{SUB_LIST.map(sub => aboutCardMap(sub, "hajnalka", aboutData))}
				<div className="featured">
					{aboutData?.hajnalka != null && aboutData?.hajnalka[SUB_FEATURED_LIST[0]] != null && (
						<>
							<h3>FEATURED</h3>
							{SUB_FEATURED_LIST.map(sub => aboutCardMap(sub, "hajnalka", aboutData))}
						</>
					)}
				</div>
			</div>
			<div className={"sarolta"}>
				{SUB_LIST.map(sub => aboutCardMap(sub, "sarolta", aboutData))}
				<div className="featured">
					{aboutData?.sarolta != null && aboutData?.sarolta[SUB_FEATURED_LIST[0]] != null && (
						<>
							<h3>FEATURED</h3>
							{SUB_FEATURED_LIST.map(sub => aboutCardMap(sub, "sarolta", aboutData))}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const AboutUsDesktop = ({ aboutData }) => {
	const [detailsOpen, setDetailsOpen] = useState(false);

	return (
		<div className={"AboutUsDesktop"}>
			<div className={"about-et-alier"}>
				<ReactMarkdown
					children={aboutData?.about?.text}
					className={"line-break"}
				/>
			</div>
			<People aboutData={aboutData}/>
			<Arrows detailsOpen={detailsOpen} setDetailsOpen={setDetailsOpen}/>
			{detailsOpen && (
				<>
					<Misc aboutData={aboutData}/>
					<Arrows detailsOpen={detailsOpen} setDetailsOpen={setDetailsOpen}/>
				</>
			)}
		</div>
	);
}