import axios from "axios";

export const getProjectsGrouped = async () => {
  return axios.get("/projectsGrouped")
    .catch(err => err);
};

export const getProjectByUrl = async (url) => {
  return axios.get(`/projects?url=${url}`)
    .catch(err => err);
};
