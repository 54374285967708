import React, {useState} from "react";
import "../AboutUs.scss";
import whiteArrowUp from "../../../media/white-arrow-up.svg";
import whiteArrowDown from "../../../media/white-arrow-down.svg";
import ReactMarkdown from "react-markdown";
import {aboutCardMap, SUB_LIST} from "../../../components/AboutCard/AboutCard";

export const AboutUsMobile = ({ aboutData }) => {

	const [saroltaOpen, setSaroltaOpen] = useState(false);
	const [hajnalkaOpen, setHajnalkaOpen] = useState(false);

	return (
		<div className={"AboutUsMobile"}>
			<div className={"story"}>
				<ReactMarkdown
					children={aboutData?.about?.text}
					className={"line-break"}
				/>
			</div>
			<div className="people">
				<div className="hajnalka">
					<img src={"/hajni.webp"} alt={"hajni"} className="image" width={3} height={2}/>
					<div className="name">
						<b>Hajnalka Illés</b>
					</div>
					<div className="about-me">
						{aboutData?.hajnalka.introduction}
					</div>
					<div className="misc">
						{hajnalkaOpen ? (
							<>
								<div onClick={() => setHajnalkaOpen(!hajnalkaOpen)} className={"cursor-pointer"}>
									<img
										src={whiteArrowUp}
										alt="white-arrow-up"
										width="50px"
										height="50px"
									/>
								</div>

								<div className={"hajnalka"}>
									{SUB_LIST.map(sub => aboutCardMap(sub, "hajnalka", aboutData))}
								</div>

								<div onClick={() => setHajnalkaOpen(!hajnalkaOpen)} className={"cursor-pointer"}>
									<img
										src={whiteArrowUp}
										alt="white-arrow-up"
										width="50px"
										height="50px"
									/>
								</div>
							</>
						) : (
							<div onClick={() => setHajnalkaOpen(!hajnalkaOpen)} className={"cursor-pointer"}>
								<img
									src={whiteArrowDown}
									alt="white-arrow-down"
									width="50px"
									height="50px"
								/>
							</div>
						)}
					</div>

				</div>

				<div className="sarolta">
					<img src={"/saci.webp"} alt={"saci"} className="image" width={3} height={2}/>
					<div className="name">
						<b>Sarolta Ágnes Erdélyi</b>
					</div>
					<div className="about-me">
						{aboutData?.sarolta.introduction}
					</div>
					<div className="misc">
						{saroltaOpen ? (
							<>
								<div onClick={() => setSaroltaOpen(!saroltaOpen)} className={"cursor-pointer"}>
									<img
										src={whiteArrowUp}
										alt="white-arrow-up"
										width="50px"
										height="50px"
									/>
								</div>

								<div className={"sarolta"}>
									{SUB_LIST.map(sub => aboutCardMap(sub, "sarolta", aboutData))}
								</div>

								<div onClick={() => setSaroltaOpen(!saroltaOpen)} className={"cursor-pointer"}>
									<img
										src={whiteArrowUp}
										alt="white-arrow-up"
										width="50px"
										height="50px"
									/>
								</div>
							</>
						) : (
							<div onClick={() => setSaroltaOpen(!saroltaOpen)} className={"cursor-pointer"}>
								<img
									src={whiteArrowDown}
									alt="white-arrow-down"
									width="50px"
									height="50px"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)

};