import React from "react";
import "./MainPageMobileHeader.scss";

import etWhite from "../../../../media/et-burger-antik-white.svg";
import etWhiteGro from "../../../../media/et-burger-gro-white.svg";
import etBlack from "../../../../media/et-burger-gro-black.svg";
import alierWhite from "../../../../media/alier-antik-white.svg";
import alierBlack from "../../../../media/alier-gro-black.svg";
import alierWhiteGro from "../../../../media/alier-gro-white.svg";
import alierBlackGro from "../../../../media/alier-antik-black.svg";
import etBlackGro from "../../../../media/et-burger-antik-black.svg";
import {useLocation} from "react-router-dom";
import styles from "../../../../styles/variables.scss";

export const MainPageMobileHeader = ({
	side,
	menuClick
}) => {
	const location = useLocation().pathname;

	const getRightImage = () => {
		if (location.includes("/about")) {
			return alierWhiteGro;
		} else if (location.includes("/contact")) {
			return alierBlackGro
		}

		return side === "left" ? alierBlack : alierWhite;
	};

	const getLeftImage = () => {
		if (location.includes("/about")) {
			return etWhiteGro;
		} else if (location.includes("/contact")) {
			return etBlackGro;
		}
		return side === "left" ? etBlack : etWhite;
	}

	const style = side === "left" ? {
		background: styles.left_background_color
	} : {
		background: styles.right_background_color
	};

	return (
		<>
			<div className={"buffer"} />
			<div className={"MainPageMobileHeader"} style={style}>
				<div className={"wrapper"}>
					<div onClick={menuClick}>
						<img src={getLeftImage()} alt={""} height={"60"} width={"40"}/>
					</div>
					<a href={"/"} rel={"canonical"} className={"right"}>
						<img src={getRightImage()} alt={""} height={"60"} width={"40"}/>
					</a>
				</div>
			</div>
		</>
	);
};