import React from "react";
import {useQuery} from "react-query";
import {getSocials} from "../../api/socials";
import "./FooterSocials.scss";

export const FooterSocials = () => {

	const { data } = useQuery("socials", getSocials, {
		retry: false,
		staleTime: Infinity
	});

	const GetSocials = () => {
		return (
			data.data.map((social, i) => {
				const separatorNeeded = i < data.data.length - 1;
				return (
						<a key={social.name} href={social.link} target="_blank" rel="noreferrer" >{social.name + (separatorNeeded ? " | " : "")}</a>
					)
			})
		);
	};

	if (data == null || data.data == null) {
		return null;
	}

	return (
		<div className={"FooterSocials"}>
			<div>
				Socials
			</div>
			<div className={"mobile-links"}>
				{data.data.map(social => (
					<a className={"link"} key={social.name} href={social.link} target="_blank" rel="noreferrer" >{social.name}</a>
				))}
			</div>
			<div className={"desktop-links"}>
				<GetSocials />
			</div>
		</div>
	);
};