import React, {memo} from "react";
import {FooterSocials} from "../FooterSocials/FooterSocials";
import "./Footer.scss";
import {FOOTER_STYLES} from "./FooterStyles";

export const RightFooter = memo(({color}) => {
	return (
		<div className={"RightFooter"} style={FOOTER_STYLES[color]}>
			<div className="work-inquiry">
				<div>
					<div>Location</div>
					<div>Budapest, Hungary</div>
				</div>
			</div>
			<div className="socials">
				<FooterSocials />
			</div>
		</div>
	);
});