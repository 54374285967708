import React, {memo, useEffect, useState} from "react";
import "./MainPage.scss";
import {MainPageSide} from "./MainPageComponents/MainPageSide/MainPageSide";
import {useQuery} from "react-query";
import {getProjectsGrouped} from "../../api/projects";
import {useLocation} from "react-router-dom";
import {ContactUs} from "../ContactUs/ContactUs";
import {AboutUs} from "../AboutUs/AboutUs";
import {MainPageMobileHeader} from "./MainPageComponents/MainPageMobileHeader/MainPageMobileHeader";
import {PhoneModal} from "../../components/PhoneModal/PhoneModal";
import {MOBILE_SCREEN_WIDTH} from "../../utils/config";
import {MainPageHeaderLeft} from "./MainPageComponents/MainPageHeader/MainPageHeaderLeft";
import {MainPageHeaderRight} from "./MainPageComponents/MainPageHeader/MainPageHeaderRight";
import {RightFooter} from "../../components/Footers/RightFooter";
import {LeftFooter} from "../../components/Footers/LeftFooter";

const GetDesktopRightSideContent = memo(({data, location}) => {
	const projects = data?.data?.out_of_the_box != null ? data.data.out_of_the_box : [];
	return (
		<div className={"content"}>
			{!location.includes("about") ? (
				<MainPageSide
					side={"right"}
					projects={projects}
				/>
			) : (
				<AboutUs/>
			)}
			<RightFooter color={"BLACK"}/>
		</div>
	);
});

const GetDesktopLeftSideContent = memo(({data, location}) => {
	const projects = data?.data?.inside_the_box != null ? data.data.inside_the_box : []
	return (
		<div className={"content" + (location.includes("contact") ? " contact" : "")}>
			{!location.includes("contact") ? (
				<>
					<MainPageSide
						side={"left"}
						projects={projects}
					/>
					<LeftFooter color={"WHITE"} />
				</>
			) : (
				<>
					<ContactUs/>
					<LeftFooter color={"WHITE"}/>
				</>
			)}
		</div>
	);
});

const GetMobileContent = memo(({location, isPhoneModalOpen, setIsPhoneModalOpen, data}) => {
	if (location === "/" || location.includes("contact")) {
		if (isPhoneModalOpen) {
			return (
				<PhoneModal
					side={"left"}
					close={() => setIsPhoneModalOpen(false)}
				/>
			);
		}
		return (
			<div className={"mobile-left"}>
				<MainPageMobileHeader side={"left"} menuClick={() => setIsPhoneModalOpen(true)}/>
				<GetDesktopLeftSideContent data={data} location={location} />
			</div>
		);
	} else {
		if (isPhoneModalOpen) {
			return (
				<PhoneModal
					side={"right"}
					close={() => setIsPhoneModalOpen(false)}
				/>
			);
		}
		return (
			<div className={"mobile-right"}>
				<MainPageMobileHeader side={"right"} menuClick={() => setIsPhoneModalOpen(true)}/>
				<GetDesktopRightSideContent data={data} location={location} />
			</div>
		);
	}
});

const GetContent = ({...props}) => {
	if (props.screenSize <= MOBILE_SCREEN_WIDTH) {
		return (
			<GetMobileContent {...props} />
		);
	}
	return (
		<div className={"split"}>
			<div className={"left"}>
				<MainPageHeaderLeft />
				<GetDesktopLeftSideContent {...props} />
			</div>
			<div className={"right"}>
				<MainPageHeaderRight />
				<GetDesktopRightSideContent {...props} />
			</div>
		</div>
	);
};

export const MainPage = () => {

	const [screenSize, setScreenSize] = useState(window.innerWidth);

	const { data } = useQuery("projectsGrouped", getProjectsGrouped, {
		retry: false,
		staleTime: Infinity
	});

	const location = useLocation().pathname;

	const force = () => setScreenSize(window.innerWidth);
	const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

	useEffect(() => {
		force();
		window.addEventListener('resize', force, true);
		return () => {
			window.removeEventListener('resize', force, true);
		};
	}, []);

	return (
		<div className={"MainPage"}>
			<GetContent
				data={data}
				location={location}
				isPhoneModalOpen={isPhoneModalOpen}
				setIsPhoneModalOpen={setIsPhoneModalOpen}
				screenSize={screenSize}
			/>
		</div>
	);
};