import React from "react";
import {Link} from "react-router-dom";
import {ProjectBlock} from "../../../../components/ProjectBlock/ProjectBlock";

const PB = ({ project, side }) => (
  <ProjectBlock
    imagePath={project?.main_image?.url}
    alt={project?.main_image?.name}
    title={project?.title}
    side={side === "left" ? "sans" : "serif"}
  />
);

export const MainPageSide = ({
   side,
   projects
}) => {

  const getLink = project => {
    let link = "/project";
    if (project?.group_code === "inside_the_box") {
      link += "/itb/"
    } else {
      link += "/otb/"
    }
    link += project.url;
    return link;
  };

  if (projects == null) {
    return null;
  }

  return (
    <div className={`MainPageSide ${side}`}>
      {projects.map(project => {
        if (project.url !== null) {
          return (
            <Link
              key={project.id}
              to={getLink(project)}
            >
              <PB project={project} side={side}/>
            </Link>
          );
        }
        return (
          <PB key={project.id} project={project} side={side} />
        );
      })}
    </div>
  );
};