import React from "react";

import "./ProjectInfoBlock.scss";

export const ProjectInfoBlock = ({ details }) => {
  return (
    <div className="ProjectInfoBlock" id={"project-info-block"}>
      <h3>Credit</h3>
      <h3 className={"slash"}>_</h3>
      {details.map(deet => {
        if (deet == null || deet.title == null || deet.value == null) {
          return null;
        }
        return (
          <div key={deet.title}>{deet.title}: {deet.value}</div>
        );
      })}
    </div>
  );
}
