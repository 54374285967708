import React, {memo} from "react";
import Lottie from "react-lottie-player";
import etAnimation from "../../../../media/et.json";
import etReverseAnimation from "../../../../media/et-reverse.json";
import etIcon from "../../../../media/et-underline-antik-black.svg";
import {isSafari} from "../../../../utils/utils";

export const LeftLogo = memo( ({left}) => {
	if (isSafari()) {
		if (left) {
			return (
				<div className={"et"}>
					<img id={"et"} src={etIcon} height={"150px"} width={"500px"} alt={""}/>
				</div>
			);
		} else {
			return (
				<div className="et">
					<Lottie
						id={"et"}
						play
						loop={false}
						animationData={etAnimation}
						style={{height: '150px', width: '360px'}}
					/>
				</div>
			);
		}
	}
	return (
		<div className="et">
			<Lottie
				id={"et"}
				play
				loop={false}
				animationData={left ? etReverseAnimation : etAnimation}
				style={{height: '150px', width: '360px'}}
			/>
		</div>
	);
});