import React, {useEffect} from "react";
import {useQuery} from "react-query";
import {getSocials} from "../../api/socials";
import {getElementsFullHeight} from "../../utils/utils";

const getSpecialThanksMarginTop = () => {
	const specialThanksElement = document.getElementById("special-thanks");
	const height = document.getElementById("root").offsetHeight;
	const headerAndFooterHeight = 200;
	const pagePadding = 150;
	const detailsHeight = getElementsFullHeight(document.getElementById("details"));
	const peopleHeight = getElementsFullHeight(document.getElementById("people-wrapper"));
	const socialsHeight = 120;
	const specialThanksHeight = getElementsFullHeight(specialThanksElement);
	const pageMargin = 90;

	const newHeight = height - (headerAndFooterHeight + pagePadding + detailsHeight + peopleHeight + socialsHeight + specialThanksHeight + pageMargin);

	if (specialThanksElement && newHeight && newHeight > 0) {
		specialThanksElement.style.marginTop = `${newHeight}px`;
	}
};

export const ContactUsSocials = () => {

	const { data } = useQuery("socials", getSocials, {
		retry: false,
		staleTime: Infinity
	});

	useEffect(() => {
		getSpecialThanksMarginTop();
	}, []);

	const GetSocials = () => {
		return (
			data.data.map((social, i) => {
				const separatorNeeded = i < data.data.length - 1;
				return (
					<span key={social.name}>
						<a href={social.link} target="_blank" rel="noreferrer" >{social.name}</a>
						{separatorNeeded && (
								<span> | </span>
						)}
					</span>
					)
			})
		);
	};

	if (data == null || data.data == null) {
		return null;
	}

	return (
		<div className={"ContactUsSocials"} id={"contact-us-socials"}>
			<div>
				Socials
			</div>
			<div className="link-wrapper">
				<GetSocials />
			</div>
		</div>
	);
};