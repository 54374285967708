import React, {useEffect} from "react";
import {LeftLogo} from "../MainPageLogo/LeftLogo";
import {Link, useLocation} from "react-router-dom";
import {EMPTY_CIRCLE, FULL_CIRCLE, getContactUsLink, getItbLink} from "../../../../utils/utils";
import "./MainPageHeader.scss";
import {leftScrollHandle, leftSideUp} from "./MainPageHeaderUtils";

export const MainPageHeaderLeft = ({ noFill }) => {

	const location = useLocation().pathname;
	const left = location.includes("/contact");

	useEffect(() =>  {
		const leftElement = document.getElementsByClassName("left")[0];
		leftElement.addEventListener("scroll", leftScrollHandle);

		return () => {
			leftElement.removeEventListener("scroll", leftScrollHandle);
		};
	}, []);

	useEffect(() => {
		leftScrollHandle();
	}, [location]);

	const leftCircle = () => {
		if (noFill) {
			return EMPTY_CIRCLE;
		}
		return left ? FULL_CIRCLE : EMPTY_CIRCLE;
	};

	const rightCircle = () => {
		if (noFill) {
			return EMPTY_CIRCLE;
		}
		return !left ? FULL_CIRCLE : EMPTY_CIRCLE;
	}

	return (
		<div className={"MainPageHeader"}>
			<div className={"header-left"}>
				<div className={"start"}>
					<LeftLogo left={left} />
				</div>
				<div className={"mid"}>
					<Link to={getContactUsLink()} onClick={leftSideUp}>
						<h3>
							Mailbox
						</h3>
						<div className={"circle"}>
							{leftCircle()}
						</div>
					</Link>
				</div>
				<div className={"end"}>
					<Link to={getItbLink()} onClick={leftSideUp}>
						<h3>
							Inside the box
						</h3>
						<div className={"circle"}>
							{rightCircle()}
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
};