import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {getProjectByUrl} from "../../api/projects";
import {useLocation} from "react-router-dom";

import "./ProjectPage.scss";
import { ProjectPageHeader } from "./ProjectPageComponents/ProjectPageHeader/ProjectPageHeader";
import { FilterBlock } from "../../components/FilterBlock/FilterBlock";
import {BASE_URL} from "../../utils/config";
import { ProjectInfoBlock } from "./ProjectPageComponents/ProjectInfoBlock/ProjectInfoBlock";
import ReactMarkdown from "react-markdown";
import {PhoneModal} from "../../components/PhoneModal/PhoneModal";
import {MainPageMobileHeader} from "../MainPage/MainPageComponents/MainPageMobileHeader/MainPageMobileHeader";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {FourOFourPage} from "../404/404page";
import {useScrollToTop} from "../../utils/useScrollToTop";
import {LeftFooter} from "../../components/Footers/LeftFooter";
import {RightFooter} from "../../components/Footers/RightFooter";
import {getElementsFullHeight} from "../../utils/utils";

const setCreditMarginTop = () => {
  const projectInfoBlockElement = document.getElementById("project-info-block");

  const height = window.innerHeight;
  const titleHeight = getElementsFullHeight(document.getElementById("title"));
  const filterHeight = getElementsFullHeight(document.getElementById("filter-block"));
  const paragraphHeight = getElementsFullHeight(document.getElementById("paragraph"));
  const projectInfoBlockHeight = projectInfoBlockElement?.offsetHeight + 64;
  const footerAndHeaderHeight = 200;
  const newHeight = height - (titleHeight + filterHeight + paragraphHeight + projectInfoBlockHeight + footerAndHeaderHeight);

  if (projectInfoBlockElement && newHeight > 0) {
    projectInfoBlockElement.style.marginTop = `${newHeight}px`;
  }
};

const CarouselWrapper = ({
  getFilteredImages
}) => {
  return (
    <Carousel
      className={"carousel"}
      infiniteLoop
      autoPlay
      interval={3000}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
    >
      {getFilteredImages().map(img => {
        if (img == null || img.url == null) {
          return null;
        }
        return (
          <div key={img.id}>
            <img src={BASE_URL + img.url} alt={img.name} width={"640"} height={"480"}/>
          </div>
        );
      })}
    </Carousel>
  );
};

const GetMainContent = ({ data }) => {
  return (
    <div className="content" id={"project-content"}>
      <h2 id={"title"} className={`title ${data.data[0]?.group_code}`}>{data.data[0]?.title}</h2>
      <FilterBlock
        filters={data.data[0]?.filters || []}
      />
      <div id={"paragraph"} className="paragraph">
        {/* https://github.com/remarkjs/react-markdown/issues/273 */}
        <ReactMarkdown
          children={data.data[0]?.paragraph.replaceAll("\n", "&nbsp;  \n")}
          className={"line-break"}
        />
      </div>

      <ProjectInfoBlock
        details={data.data[0]?.details}
      />
    </div>
  );
};

export const ProjectPage = () => {
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const location = useLocation().pathname;

  useScrollToTop();

  const getUrl = () => {
    const urlArray = location.split("/");
    return urlArray[urlArray.length - 1];
  };

  const getSide = () => {
    const urlArray = location.split("/");
    return urlArray[urlArray.length - 2];
  };

  const [ side ] = useState(getSide());

  const { isLoading, data } = useQuery("projectByUrl", () => getProjectByUrl(getUrl()), {
    retry: false
  });

  useEffect(() => {
    return () => {
      setCreditMarginTop();
    }
  }, [data]);

  const getFilteredImages = () => {
    return data.data[0].images.filter(d => d.url != null);
  };

  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  if (data.data == null || data.data.length === 0) {
    return (
      <FourOFourPage />
    );
  }

  const leftOrRight = side === "itb" ? "left" : "right";

  return (
    <div className={`ProjectPage ${side}`}>
      <div className={"desktop"}>
        <div className="split">
          <div className="left">
            <ProjectPageHeader
              side={"left"}
            />
            <div className="content">
              <div className="images">
                {data.data[0]?.images.map(img => {
                  if (img == null || img.url == null) {
                    return null;
                  }
                  return (
                    <div className="image-wrapper" key={img.id}>
                      <img src={BASE_URL + img.url} alt={img.name} width={"640"} height={"480"}/>
                    </div>
                  );
                })}
              </div>
            </div>
            <LeftFooter color={side === "itb" ? "WHITE" : "BLACK"} style={{
              marginLeft: "30px",
              marginRight: "0px",
              width: "calc(50vw - 30px)"
            }}/>
          </div>
          <div className="right">
            <ProjectPageHeader
              side={"right"}
            />
            <GetMainContent data={data}/>
            <RightFooter color={side === "itb" ? "WHITE" : "BLACK"}/>
          </div>
        </div>
      </div>

      <div className={"mobile"}>
        {isPhoneModalOpen ? (
          <PhoneModal
            side={leftOrRight}
            close={() => setIsPhoneModalOpen(false)}
          />
        ) : (
          <>
            <MainPageMobileHeader side={leftOrRight} menuClick={() => setIsPhoneModalOpen(true)}/>
            <div className={"mobile-content"}>
              <CarouselWrapper getFilteredImages={getFilteredImages}/>
              <GetMainContent data={data}/>
            </div>
            <LeftFooter color={side === "itb" ? "WHITE" : "BLACK"}/>
          </>
        )}
      </div>
    </div>
  );
};