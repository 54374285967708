import React, {useEffect, useState} from "react";
import {MOBILE_SCREEN_WIDTH} from "../../utils/config";
import {AboutUsMobile} from "./AboutUsComponents/AboutUsMobile";
import {AboutUsDesktop} from "./AboutUsComponents/AboutUsDesktop";
import {useQuery} from "react-query";
import {getAbout} from "../../api/about";
import {getPeople} from "../../api/people";

export const AboutUs = () => {
  const screenWidth = window.innerWidth;

  const [aboutData, setAboutData] = useState(null);

  const { data: about } = useQuery("about", getAbout, {
    retry: false,
    enabled: aboutData == null,
    staleTime: Infinity
  });
  const { data: people } = useQuery("people", getPeople, {
    retry: false,
    enabled: aboutData == null,
    staleTime: Infinity
  });

  useEffect(() => {
    if (about != null && people != null && people.data != null) {
      setAboutData({
        about: about.data,
        hajnalka: people.data.filter(x => x.name === "Hajnalka")[0],
        sarolta: people.data.filter(x => x.name === "Sarolta")[0]
      });
    }
  }, [about, people])

  if (screenWidth < MOBILE_SCREEN_WIDTH) {
    return (
      <AboutUsMobile aboutData={aboutData} />
    );
  } else {
    return (
      <AboutUsDesktop aboutData={aboutData} />
    );
  }
}