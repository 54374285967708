import React from "react";

import "./ProjectTitle.scss";

export const ProjectTitle = ({
    title,
    side
}) => {
    if (!title || title === "") {
      return null;
    }
    return (
        <div className={"project-title"}>
            <div className={"title " + side}>
                {title}
            </div>
        </div>
    );
}

export default ProjectTitle;