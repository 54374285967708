
export const getContactUsLink = () => {
	if (window.location.href.includes("about")) {
		return "/contact/about";
	} else {
		return "/contact";
	}
};

export const getOtbLink = () => {
	if (window.location.href.includes("contact")) {
		return "/contact/otb";
	} else {
		return "/";
	}
};

export const getAboutLink = () => {
	if (window.location.href.includes("contact")) {
		return "/contact/about";
	} else {
		return "/about";
	}
};

export const getItbLink = () => {
	if (window.location.href.includes("about")) {
		return "/about";
	} else {
		return "/";
	}
};

export const isSafari = () => {
	return navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") === -1;
};

export const EMPTY_CIRCLE = "○";
export const FULL_CIRCLE =  "●";

export const getElementsFullHeight = (element) => {
	if (!element) {
		return 0;
	}
	const style = window.getComputedStyle(element);
	return parseInt(style.height) + parseInt(style.marginTop) + parseInt(style.marginBottom);
}